import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notificationMsg: '',
  status: 'info',
  show: false
};

const notification = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification(state, action) {
      state.notificationMsg = action.payload.notificationMsg;
      state.status = action.payload.status;
      state.show = true;
    },
    hideNotification(state) {
      state.show = false;
    }
  }
});

export default notification.reducer;

export const { showNotification, hideNotification } = notification.actions;
