import { combineReducers } from 'redux';

import menu from './menu';
import notification from './notification';
import loader from './loader';

const reducers = combineReducers({
  menu,
  notification,
  loader
});

export default reducers;
