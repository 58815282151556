import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';

import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from 'components/Logo';

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      {open && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ marginLeft: '-20px' }}
        >
          <Logo open={open} />
        </Stack>
      )}
      {!open && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ overflow: 'hidden' }}
        >
          <Logo open={open} />
        </Stack>
      )}
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
