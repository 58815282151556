import { Box, useMediaQuery } from '@mui/material';

import Profile from './Profile';
import Notification from './Notification';

import { xType } from 'config/axios';

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const user = localStorage.getItem(xType);

  return (
    <>
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      {user !== 'admin' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Notification />
        </div>
      )}
      {!matchesXs && <Profile />}
    </>
  );
};

export default HeaderContent;
