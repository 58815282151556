import dayjs from 'dayjs';

import {
  xType,
  xUser,
  xToken,
  xAuthenticated,
  xUserMobile,
  xUserName
} from 'config/axios';

const clearLocalStorage = () => {
  localStorage.setItem(xType, '');
  localStorage.setItem(xUser, '');
  localStorage.setItem(xToken, '');
  localStorage.setItem(xAuthenticated, false);
  localStorage.setItem(xUserMobile, '');
  localStorage.setItem(xUserName, '');
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const convertToCSV = (objArray) => {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';
  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
      if (line != '') line += ',';
      line += array[i][index];
    }
    str += line + '\r\n';
  }
  return str;
};

const exportToCSV = (data, fileName) => {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const downloadCSV = (data, headers, fileName) => {
  const tList = [...data];
  tList.unshift(headers);

  const jsonObject = JSON.stringify(tList);
  const csv = convertToCSV(jsonObject);
  exportToCSV(csv, fileName);
};

const getEntityName = (data) => {
  return data.tradeNam && data.tradeNam !== '' ? data.tradeNam : data.Ignm;
};

const addCommaInNumber = (num) => {
  if (num) return parseFloat(num).toLocaleString('en-US');
  return num;
};

const getPinCodeCity = (address) => {
  if (address.Circle !== 'NA') {
    return address.Circle;
  } else if (address.Circle !== 'NA') {
    return address.District;
  } else {
    return address.Division;
  }
};

const getFormattedDate = (date, format = '') => {
  if (date && format === '') return dayjs(date).format('DD-MM-YYYY HH:mm:ss');
  if (date && format !== '') return dayjs(date).format(format);
  else date;
};

export {
  clearLocalStorage,
  getComparator,
  stableSort,
  convertToCSV,
  downloadCSV,
  exportToCSV,
  getEntityName,
  addCommaInNumber,
  getPinCodeCity,
  getFormattedDate
};
