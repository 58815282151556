import { lazy } from 'react';

import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const OTP = Loadable(lazy(() => import('pages/authentication/OTP')));
const ForgotPassword = Loadable(
  lazy(() => import('pages/authentication/ForgotPassword'))
);

const LoginRoutes = {
  element: <MinimalLayout />,
  children: [
    {
      path: '/admin',
      element: <AuthLogin />
    },
    {
      path: '/admin/otp-verify',
      element: <OTP />
    },
    {
      path: '/admin/forgot-password',
      element: <ForgotPassword />
    }
  ]
};

export default LoginRoutes;
