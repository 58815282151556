import { configureStore } from '@reduxjs/toolkit';

import reducers from './reducers';

const store = configureStore({
  reducer: reducers
});

const { dispatch } = store;

const { RootState } = store.getState;

export { store, dispatch, RootState };
