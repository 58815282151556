import React from 'react';
import { useRef, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  Button
} from '@mui/material';

import { xNotification } from 'config/axios';

import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';

import {
  BellOutlined,
  FormOutlined,
  ChromeOutlined,
  CloseOutlined
} from '@ant-design/icons';

import { getFormattedDate } from 'utils';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

const Notification = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const [notification, setNotification] = useState([]);

  const updateNotification = () => {
    if (localStorage.getItem(xNotification)) {
      setNotification(JSON.parse(localStorage.getItem(xNotification)));
    }
  };

  useEffect(() => {
    const notify = JSON.parse(localStorage.getItem(xNotification));
    setNotification(notify);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      updateNotification();
      // Update notification in every 30 second
    }, 30000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleClear = () => {
    setNotification([]);
    localStorage.setItem(xNotification, JSON.stringify([]));
  };

  const handleDeleteNotification = (index) => {
    if (index > -1) {
      const newNotification = [...notification];
      newNotification.splice(index, 1);
      localStorage.setItem(xNotification, JSON.stringify(newNotification));
      setNotification(newNotification);
    }
  };

  const handleNavigation = (index) => {
    const notify = notification[index];
    if (notify) {
      if (notify.type === 'TASK' && notify.id !== '') {
        navigate(`/dashboard/task/view/${notify.id}`);
      }
    }
  };

  const iconBackColorOpen = 'grey.300';
  const iconBackColor = 'grey.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        disableRipple
        color="secondary"
        sx={{
          color: 'text.primary',
          bgcolor: open ? iconBackColorOpen : iconBackColor
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge
          badgeContent={
            notification && notification.length > 0 && !open
              ? notification.length
              : 0
          }
          color="primary"
        >
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                width: 400,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notification"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <Button variant="text" onClick={handleClear}>
                      CLEAR ALL
                    </Button>
                  }
                  sx={{
                    '& .MuiCardHeader-root': {
                      padding: '5px 20px'
                    }
                  }}
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      height: '400px',
                      overflowY: 'auto',
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': {
                          ...actionSX,
                          position: 'relative'
                        }
                      }
                    }}
                  >
                    {notification.map(
                      ({ type, notification, id, timestamp }, index) => {
                        return (
                          <React.Fragment
                            key={`notification-${index}-${type}-${id}`}
                          >
                            <ListItemButton>
                              <ListItemAvatar>
                                {type === 'TICKET' && (
                                  <Avatar
                                    sx={{
                                      color: 'primary.main',
                                      bgcolor: 'primary.lighter'
                                    }}
                                  >
                                    <FormOutlined />
                                  </Avatar>
                                )}

                                {type === 'TASK' && (
                                  <Avatar
                                    sx={{
                                      color: 'success.main',
                                      bgcolor: 'success.lighter'
                                    }}
                                  >
                                    <ChromeOutlined />
                                  </Avatar>
                                )}
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <Typography variant="h6">
                                    {notification}
                                  </Typography>
                                }
                                secondary={getFormattedDate(timestamp)}
                                onClick={() => handleNavigation(index)}
                              />
                              <ListItemAvatar
                                sx={{
                                  minWidth: '20px'
                                }}
                                onClick={() => handleDeleteNotification(index)}
                              >
                                <CloseOutlined />
                              </ListItemAvatar>
                            </ListItemButton>
                            <Divider />
                          </React.Fragment>
                        );
                      }
                    )}
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
