import { lazy } from 'react';

import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const ThankYouRegister = Loadable(
  lazy(() => import('pages/authentication/ThankYouRegister'))
);
const OTP = Loadable(lazy(() => import('pages/authentication/OTP')));
const MobileOTP = Loadable(
  lazy(() => import('pages/authentication/MobileOTP'))
);
const SignUp = Loadable(lazy(() => import('pages/authentication/SignUp')));
const AuthRegister = Loadable(
  lazy(() => import('pages/authentication/Register'))
);
const LoginWithOTP = Loadable(
  lazy(() => import('pages/authentication/LoginWithOTP'))
);
const ForgotPassword = Loadable(
  lazy(() => import('pages/authentication/ForgotPassword'))
);
const ThankYouSentEmail = Loadable(
  lazy(() => import('pages/authentication/ThankYouSentEmail'))
);
const SetNewPassword = Loadable(
  lazy(() => import('pages/authentication/SetNewPassword'))
);
const ThankYouResetPassword = Loadable(
  lazy(() => import('pages/authentication/ThankYouResetPassword'))
);

const LoginRoutes = {
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <AuthLogin />
    },
    {
      path: '/admin',
      element: <AuthLogin />
    },
    {
      path: 'register',
      element: <SignUp />
    },
    {
      path: 'register-form',
      element: <AuthRegister />
    },
    {
      path: '/login-with-otp',
      element: <LoginWithOTP />
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />
    },
    {
      path: 'otp-verify',
      element: <OTP />
    },
    {
      path: '/mobile/otp-verify',
      element: <MobileOTP />
    },
    {
      path: 'thank-you-register',
      element: <ThankYouRegister />
    },
    {
      path: 'thank-you-sent-email',
      element: <ThankYouSentEmail />
    },
    {
      path: '/set_new_pass',
      element: <SetNewPassword />
    },
    {
      path: 'thank-you-reset-password',
      element: <ThankYouResetPassword />
    }
  ]
};

export default LoginRoutes;
