import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ButtonBase,
  ClickAwayListener,
  Paper,
  Popper,
  Stack,
  Typography,
  Grid
} from '@mui/material';

import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import ProfileTab from './ProfileTab';

import { clearLocalStorage } from 'utils';

import { xType, xUserName } from 'config/axios';

const Profile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const user = localStorage.getItem(xType);

  const handleLogout = async () => {
    clearLocalStorage();
    if (user !== 'admin') {
      navigate('/');
    } else {
      navigate('/admin');
    }
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleChangePassword = async () => {
    setOpen(false);
    navigate('/dashboard/change-password');
  };

  const handleProfile = async () => {
    setOpen(false);
    navigate('/dashboard/profile');
  };

  const iconBackColorOpen = 'grey.300';

  return (
    <Box
      sx={{
        flexShrink: 0,
        ml: 0.75,
        width: user === 'admin' ? '99%' : 'unset',
        display: 'flex',
        justifyContent: 'end'
      }}
    >
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: 'secondary.lighter' }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar
            src="assets/images/icons/broken-image.jpg"
            sx={{ width: 32, height: 32 }}
          />
          {localStorage.getItem(xUserName) !== '' && (
            <Typography
              variant="h6"
              color="primary"
              sx={{
                textTransform: 'capitalize'
              }}
            >
              {user !== 'admin' ? localStorage.getItem(xUserName) : 'Admin'}
            </Typography>
          )}
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    {open && (
                      <ProfileTab
                        handleLogout={handleLogout}
                        handleChangePassword={handleChangePassword}
                        handleProfile={handleProfile}
                      />
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
