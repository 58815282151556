import { useRoutes } from 'react-router-dom';

import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import AdminMainRoutes from './AdminMainRoutes';
import AdminLoginRoutes from './AdminLoginRoutes';

export default function ThemeRoutes() {
  return useRoutes([
    LoginRoutes,
    MainRoutes,
    AdminMainRoutes,
    AdminLoginRoutes
  ]);
}
