import { Box, Typography } from '@mui/material';

import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import adminMenuItem from 'admin-menu-items';
import { xType } from 'config/axios';

const Navigation = () => {
  const itemList =
    localStorage.getItem(xType) === 'admin' ? adminMenuItem : menuItem;

  const navGroups = itemList.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
