import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, List, Typography } from '@mui/material';

import NavItem from './NavItem';
import NavCollpase from './NavCollpase';

const NavGroup = ({ item }) => {
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case 'collapse':
        return <NavCollpase key={menuItem.id} item={menuItem} level={1} />;
      case 'item':
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography
            key={menuItem.id}
            variant="h6"
            color="error"
            align="center"
          >
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return (
    <List
      subheader={
        item.title &&
        drawerOpen && (
          <Box sx={{ pl: 3, mb: 1.5 }}>
            <Typography variant="subtitle2" color="textSecondary">
              {item.title}
            </Typography>
          </Box>
        )
      }
      sx={{
        mb: drawerOpen ? 1.5 : 0,
        py: 0,
        zIndex: 0,
        height: item.height
          ? drawerOpen
            ? 'calc(100vh - 220px)'
            : 'calc(100vh - 160px)'
          : 'inherit',
        overflowY: 'auto'
      }}
    >
      {navCollapse}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;
