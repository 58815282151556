import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  useMediaQuery,
  Container,
  Snackbar,
  Alert,
  AlertTitle,
  Backdrop,
  CircularProgress
} from '@mui/material';

import axiosInstance, {
  xNotification,
  xType,
  xUserTypeUser,
  xAuthenticated,
  xToken
} from 'config/axios';

import Drawer from './Drawer';
import Header from './Header';

import { clearLocalStorage } from 'utils';

import { openDrawer } from 'store/reducers/menu';
import {
  hideNotification,
  showNotification
} from 'store/reducers/notification';
import { setShowLoader } from 'store/reducers/loader';
import * as api from 'config/api.const';
import * as notify from 'config/notify.const';

const MainLayout = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const token = localStorage.getItem(xToken);

  const matchDownLG = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state) => state.menu);

  const { showLoader } = useSelector((state) => state.loader);

  const { notificationMsg, status, show } = useSelector(
    (state) => state.notification
  );

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  useEffect(() => {
    dispatch(setShowLoader(false));
  }, [show, dispatch]);

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(hideNotification());
  };

  const [user, setUser] = useState(localStorage.getItem(xType));
  const isAuthenticated = localStorage.getItem(xAuthenticated);

  useEffect(() => {
    setUser(user);
  }, [user]);

  useEffect(() => {
    const pathName = window.location.pathname;
    if (isAuthenticated === 'false') {
      clearLocalStorage();
      if (pathName.includes('admin') === false) {
        navigate('/');
      } else {
        navigate('/admin');
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    const pathName = window.location.pathname;

    if (token === '' || !token) {
      if (pathName.includes('admin') === false) {
        navigate('/');
      } else {
        navigate('/admin');
      }
    }
  }, [token, navigate]);

  const getNotification = () => {
    if (user && user === xUserTypeUser) {
      try {
        axiosInstance
          .post(api.API_GET_NOTIFICATION)
          .then(({ data }) => {
            let notification = [];
            if (localStorage.getItem(xNotification)) {
              notification = JSON.parse(localStorage.getItem(xNotification));
            }
            const total = [...data, ...notification];
            localStorage.setItem(xNotification, JSON.stringify(total));
          })
          .catch((err) => {
            dispatch(
              showNotification({
                notificationMsg: `${notify.ERROR_GET_NOTIFICATION} : ${err}`,
                status: 'error'
              })
            );
          });
      } catch (err) {
        dispatch(
          showNotification({
            notificationMsg: `${notify.ERROR_GET_NOTIFICATION} : ${err}`,
            status: 'error'
          })
        );
      }
    }
  };

  useEffect(() => {
    getNotification();
    const timer = setInterval(() => {
      getNotification();
      // Fetch notification in every 10 min
    }, 600000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1000
        }}
        open={showLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{ width: '100%', flexGrow: 1 }}>
        <Toolbar />
        <Container
          maxWidth="xl"
          sx={{
            padding: '24px'
          }}
        >
          <Outlet />
        </Container>
      </Box>
      <Snackbar
        open={show}
        autoHideDuration={status === 'error' ? 8000 : 4000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          severity={status}
          onClose={handleCloseNotification}
          sx={{
            fontSize: '14px'
          }}
        >
          <AlertTitle
            sx={{
              textTransform: 'capitalize',
              fontSize: '18px !important'
            }}
          >
            {status}
          </AlertTitle>
          <h4>{notificationMsg}</h4>
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MainLayout;
