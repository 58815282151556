import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showLoader: false
};

const loader = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setShowLoader(state, action) {
      state.showLoader = action.payload;
    }
  }
});

export default loader.reducer;

export const { setShowLoader } = loader.actions;
