import PropTypes from 'prop-types';
import logo from 'assets/images/logo.jpeg';
import logoWithText from 'assets/images/logo-with-text.jpeg';

const Logo = ({ open }) => {
  return (
    <div className="logo-div" style={{
      textAlign: 'center',
    }}>
      {open && <img src={logoWithText} alt="Enviro" width="100%" height={'auto'} style={{padding: '10px'}}/>}
      {!open && <img src={logo} alt="Enviro" width="100%" height={'60px'}/>}
    </div>
  );
};

Logo.propTypes = {
  open: PropTypes.bool
};

export default Logo;
