import {
  AimOutlined,
  CheckCircleOutlined,
  SketchOutlined,
  ProfileOutlined,
  AntDesignOutlined,
  LogoutOutlined,
  BarsOutlined,
  FormOutlined,
  SlackSquareOutlined,
  Loading3QuartersOutlined,
  PieChartOutlined,
  AppstoreAddOutlined,
  AccountBookOutlined,
  FilterOutlined,
  QuestionCircleOutlined,
  AlignCenterOutlined,
  BranchesOutlined,
  IssuesCloseOutlined,
  FolderOpenOutlined,
  ContainerOutlined,
  DiffOutlined,
  CodepenOutlined,
  GitlabOutlined,
  ClusterOutlined,
  StopOutlined
} from '@ant-design/icons';

const AdminSupport = {
  id: 'support',
  title: '',
  type: 'group',
  height: 'calc(100vh - 220px)',
  children: [
    {
      id: 'customers',
      title: 'Customers',
      tooltip: 'Customers',
      type: 'collapse',
      icon: AimOutlined,
      child: [
        {
          id: 'cust-all',
          title: 'All',
          tooltip: 'All Customers',
          type: 'item',
          url: '/admin/dashboard/customers/all',
          icon: ProfileOutlined
        },
        {
          id: 'cust-suspend',
          title: 'Suspended',
          tooltip: 'Suspended Customers',
          type: 'item',
          url: '/admin/dashboard/customers/suspended',
          icon: SketchOutlined
        },
        {
          id: 'cust-pending',
          title: 'Pending Approval',
          tooltip: 'Pending Approval Customers',
          type: 'item',
          url: '/admin/dashboard/customers/pending',
          icon: CheckCircleOutlined
        },
        {
          id: 'cust-registered',
          title: 'To Regsiter',
          tooltip: 'To Regsiter Customers',
          type: 'item',
          url: '/admin/dashboard/customers/registered',
          icon: CodepenOutlined
        }
      ]
    },
    {
      id: 'tasks',
      title: 'Tasks',
      tooltip: 'Tasks',
      type: 'collapse',
      icon: BarsOutlined,
      child: [
        {
          id: 'task-all',
          title: 'All',
          tooltip: 'All Tasks',
          type: 'item',
          url: '/admin/dashboard/tasks/all',
          icon: AntDesignOutlined
        },
        {
          id: 'task-pendingaction',
          title: 'To Reply',
          tooltip: 'To Reply Tasks',
          type: 'item',
          url: '/admin/dashboard/tasks/pendingaction',
          icon: ContainerOutlined
        },
        {
          id: 'task-negotiation',
          title: 'In Negotiation',
          tooltip: 'In Negotiation Tasks',
          type: 'item',
          url: '/admin/dashboard/tasks/negotiation',
          icon: FormOutlined
        },
        {
          id: 'task-inprogress',
          title: 'InProgress',
          tooltip: 'InProgress Tasks',
          type: 'item',
          url: '/admin/dashboard/tasks/inprogress',
          icon: Loading3QuartersOutlined
        },
        {
          id: 'task-pendingquote',
          title: 'Pending Quote',
          tooltip: 'Pending Quote Tasks',
          type: 'item',
          url: '/admin/dashboard/tasks/pendingquote',
          icon: PieChartOutlined
        },
        {
          id: 'task-declinedquote',
          title: 'Declined Quote',
          tooltip: 'Declined Quote Tasks',
          type: 'item',
          url: '/admin/dashboard/tasks/declinedquote',
          icon: StopOutlined
        },
        {
          id: 'task-overdue',
          title: 'To Settle',
          tooltip: 'To Settle Tasks',
          type: 'item',
          url: '/admin/dashboard/tasks/overdue',
          icon: AppstoreAddOutlined
        },
        {
          id: 'task-filters',
          title: 'Filters',
          tooltip: 'Filters On Tasks',
          type: 'item',
          url: '/admin/dashboard/tasks/filters',
          icon: FilterOutlined
        }
      ]
    },
    {
      id: 'query',
      title: 'Query',
      tooltip: 'Query',
      type: 'collapse',
      icon: QuestionCircleOutlined,
      child: [
        {
          id: 'ticket-all',
          title: 'All',
          tooltip: 'All Query',
          type: 'item',
          url: '/admin/dashboard/query/all',
          icon: AlignCenterOutlined
        },
        {
          id: 'ticket-new',
          title: 'New',
          tooltip: 'New Query',
          type: 'item',
          url: '/admin/dashboard/query/new',
          icon: SlackSquareOutlined
        },
        {
          id: 'ticket-pending',
          title: 'Pending',
          tooltip: 'Pending Query',
          type: 'item',
          url: '/admin/dashboard/query/pending',
          icon: AccountBookOutlined
        },
        {
          id: 'ticket-open',
          title: 'Open',
          tooltip: 'Open Query',
          type: 'item',
          url: '/admin/dashboard/query/open',
          icon: FolderOpenOutlined
        },
        {
          id: 'ticket-closed',
          title: 'Closed',
          tooltip: 'Closed Query',
          type: 'item',
          url: '/admin/dashboard/query/closed',
          icon: IssuesCloseOutlined
        }
      ]
    },
    {
      id: 'reports',
      title: 'Reports',
      tooltip: 'Reports',
      type: 'collapse',
      icon: DiffOutlined,
      child: [
        {
          id: 'report-task',
          title: 'Task',
          tooltip: 'Task Report',
          type: 'item',
          url: '/admin/dashboard/report/task',
          icon: BranchesOutlined
        },
        {
          id: 'report-revenue',
          title: 'Revenue',
          tooltip: 'Revenue Report',
          type: 'item',
          url: '/admin/dashboard/report/revenue',
          icon: GitlabOutlined
        }
      ]
    },
    {
      id: 'track',
      title: 'Track',
      tooltip: 'Track On Truck',
      type: 'item',
      url: '/admin/dashboard/track',
      icon: ClusterOutlined
    },
    {
      id: 'logout',
      tooltip: 'Logout',
      title: 'Logout',
      type: 'item',
      url: '/admin',
      icon: LogoutOutlined
    }
  ]
};

export default AdminSupport;
