import {
  BarsOutlined,
  ProfileOutlined,
  ContactsOutlined,
  LogoutOutlined,
  FormOutlined,
  AppstoreOutlined,
  DiffOutlined,
  AlignCenterOutlined,
  FolderOpenOutlined,
  IssuesCloseOutlined,
  ReconciliationOutlined,
  Loading3QuartersOutlined,
  CheckSquareOutlined,
  FilterOutlined,
  StopOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';

const support = {
  id: 'support',
  title: '',
  type: 'group',
  height: 'calc(100vh - 220px)',
  children: [
    {
      id: 'profile',
      tooltip: 'Profile',
      title: 'Profile',
      type: 'item',
      url: '/dashboard/profile',
      icon: ProfileOutlined
    },
    {
      id: 'sites',
      title: 'Sites',
      tooltip: 'Sites',
      type: 'item',
      url: '/dashboard/sites',
      icon: AppstoreOutlined
    },
    {
      id: 'tasks',
      title: 'Tasks',
      tooltip: 'Tasks',
      type: 'collapse',
      icon: BarsOutlined,
      child: [
        {
          id: 'task-all',
          title: 'All',
          tooltip: 'All Tasks',
          type: 'item',
          url: '/dashboard/tasks/all',
          icon: ReconciliationOutlined
        },
        {
          id: 'task-pendingaction',
          title: 'Pending Admin Quote',
          tooltip: 'Pending Admin Quote Tasks',
          type: 'item',
          url: '/dashboard/tasks/pendingquote',
          icon: FormOutlined
        },
        {
          id: 'task-inprogress',
          title: 'InProgress',
          tooltip: 'InProgress Tasks',
          type: 'item',
          url: '/dashboard/tasks/inprogress',
          icon: Loading3QuartersOutlined
        },
        {
          id: 'task-toaccept',
          title: 'To Accept',
          tooltip: 'To Accept Tasks',
          type: 'item',
          url: '/dashboard/tasks/toaccept',
          icon: CheckSquareOutlined
        },
        {
          id: 'task-declinedquote',
          title: 'Declined Quote',
          tooltip: 'Declined Quote Tasks',
          type: 'item',
          url: '/dashboard/tasks/declinedquote',
          icon: StopOutlined
        },
        {
          id: 'task-filters',
          title: 'Filters',
          tooltip: 'Filters On Tasks',
          type: 'item',
          url: '/dashboard/tasks/filters',
          icon: FilterOutlined
        }
      ]
    },
    {
      id: 'query',
      title: 'Query',
      tooltip: 'Query',
      type: 'collapse',
      icon: QuestionCircleOutlined,
      child: [
        {
          id: 'ticket-all',
          title: 'All',
          tooltip: 'All Query',
          type: 'item',
          url: '/dashboard/query/all',
          icon: AlignCenterOutlined
        },
        {
          id: 'ticket-open',
          title: 'Open',
          tooltip: 'Open Query',
          type: 'item',
          url: '/dashboard/query/open',
          icon: FolderOpenOutlined
        },
        {
          id: 'ticket-closed',
          title: 'Closed',
          tooltip: 'Closed Query',
          type: 'item',
          url: '/dashboard/query/closed',
          icon: IssuesCloseOutlined
        }
      ]
    },
    {
      id: 'contact-us',
      title: 'Contact Us',
      tooltip: 'Contact Us',
      type: 'item',
      url: '/dashboard/contact-us',
      icon: ContactsOutlined
    },
    {
      id: 'report',
      title: 'Report',
      tooltip: 'Report',
      type: 'item',
      url: '/dashboard/report',
      icon: DiffOutlined
    },
    {
      id: 'logout',
      title: 'Logout',
      tooltip: 'Logout',
      type: 'item',
      url: '/',
      icon: LogoutOutlined
    }
  ]
};

export default support;
