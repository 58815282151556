import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import {
  ProfileOutlined,
  LogoutOutlined,
  WalletOutlined
} from '@ant-design/icons';

import { xType } from 'config/axios';

const ProfileTab = ({ handleLogout, handleProfile, handleChangePassword }) => {
  const theme = useTheme();
  const user = localStorage.getItem(xType);

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        '& .MuiListItemIcon-root': {
          minWidth: 32,
          color: theme.palette.grey[500]
        }
      }}
    >
      {user !== 'admin' && (
        <>
          <ListItemButton onClick={() => handleProfile()}>
            <ListItemIcon>
              <ProfileOutlined />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItemButton>
          <ListItemButton onClick={() => handleChangePassword()}>
            <ListItemIcon>
              <WalletOutlined />
            </ListItemIcon>
            <ListItemText primary="Change Password" />
          </ListItemButton>
        </>
      )}
      <ListItemButton onClick={() => handleLogout()}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func,
  handleChangePassword: PropTypes.func,
  handleProfile: PropTypes.func
};

export default ProfileTab;
