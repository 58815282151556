import { useEffect } from 'react';
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';

import { xRefreshTimer } from 'config/axios';

const App = () => {
  const reloadApp = () => {
    const reloadTimer = localStorage.getItem(xRefreshTimer);
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const diffTime = Math.abs(currentTimestamp - reloadTimer);
    // 12hr in second: 43200
    if (diffTime >= 43200) {
      localStorage.setItem(xRefreshTimer, currentTimestamp);
      window.location.reload(true);
    }
  };

  useEffect(() => {
    reloadApp();
    const timer = setInterval(() => {
      // Fetch Data Modified in every 10 min, 600000 millisecond
      reloadApp();
    }, 600000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeCustomization>
      <ScrollTop>
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
  );
};

export default App;
