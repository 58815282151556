import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ButtonBase } from '@mui/material';

import Logo from './Logo';

const LogoSection = ({ sx, open }) => (
  <ButtonBase disableRipple component={Link} sx={sx} style={{ width: '100%' }}>
    <Logo open={open} />
  </ButtonBase>
);

LogoSection.propTypes = {
  sx: PropTypes.object,
  open: PropTypes.bool
};

export default LogoSection;
